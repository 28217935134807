var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('h4',[_vm._v(_vm._s(_vm.$t('Informations')))]),_c('hr',{staticStyle:{"margin-bottom":"16px"}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Asset Identifier'),"label-for":"asset"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsAsset,"input-props":_vm.inputPropsAsset,"get-suggestion-value":_vm.getSuggestionValue},on:{"selected":_vm.selectHandlerAsset,"input":_vm.onInputChangeAsset},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('div',[_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.plate || suggestion.item.chassisNumber))])]),_c('div',[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('Are there devices installed?')))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(suggestion.item.hasDevicesInstalled ? _vm.$t('Yes') : _vm.$t('No'))+" ")])])]}}]),model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Installation Date'),"label-for":"installation-date"}},[_c('validation-provider',{attrs:{"name":"installation-date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",model:{value:(_vm.deviceData.installationDate),callback:function ($$v) {_vm.$set(_vm.deviceData, "installationDate", $$v)},expression:"deviceData.installationDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Installer Technician'),"label-for":"tec"}},[_c('validation-provider',{attrs:{"name":"tec","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tec"},model:{value:(_vm.deviceData.instTechnicianName),callback:function ($$v) {_vm.$set(_vm.deviceData, "instTechnicianName", $$v)},expression:"deviceData.instTechnicianName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Installed with lock')+'*',"label-for":"type-customer"}},[_c('validation-provider',{attrs:{"name":"type-customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.lockingOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"type"},model:{value:(_vm.deviceData.hasBlock),callback:function ($$v) {_vm.$set(_vm.deviceData, "hasBlock", $$v)},expression:"deviceData.hasBlock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Note'),"label-for":"note"}},[_c('b-form-textarea',{attrs:{"id":"note","rows":"5"},model:{value:(_vm.deviceData.note),callback:function ($$v) {_vm.$set(_vm.deviceData, "note", $$v)},expression:"deviceData.note"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }