<template>
  <b-card>

    <validation-observer ref="simpleRules">
      <b-form>
        <!-- Section Informations -->
        <h4>{{ $t('Informations') }}</h4>
        <hr style="margin-bottom: 16px;">
        <b-row>
          <!-- Field: Asset -->
          <b-col cols="12" md="4">

            <b-form-group :label="$t('Asset Identifier')" label-for="asset">
              <vue-autosuggest v-model="searchQuery" :suggestions="filteredOptionsAsset" :input-props="inputPropsAsset"
                               @selected="selectHandlerAsset" @input="onInputChangeAsset"
                               :get-suggestion-value="getSuggestionValue">
                <template slot-scope="{suggestion}">
                  <div>
                    <span class="my-suggestion-item">{{ suggestion.item.plate || suggestion.item.chassisNumber }}</span>
                  </div>
                  <div>
                    <span class="text-muted">{{ $t('Are there devices installed?') }}</span>
                    <span class="font-weight-bold">
                                        {{ suggestion.item.hasDevicesInstalled ? $t('Yes') : $t('No') }}
                                    </span>
                  </div>
                </template>
              </vue-autosuggest>

            </b-form-group>
          </b-col>

          <!-- Field: Device 
          <b-col cols="12" md="4">

              <b-form-group label="Device Identifier" label-for="device">
                  <vue-autosuggest v-model="searchQueryDevice" :suggestions="filteredOptionsDevice" :input-props="inputPropsDevice" @selected="selectHandlerDevice" @input="onInputChangeDevice">
                      <template slot-scope="{suggestion}">
                          <span class="my-suggestion-item">{{ suggestion.item.plate ? suggestion.item.plate : suggestion.item.chassisNumber}}</span>
                      </template>
                  </vue-autosuggest>

              </b-form-group>
          </b-col>-->

          <!-- Field: Installation Date -->
          <b-col cols="12" md="3">
            <b-form-group :label="$t('Installation Date')" label-for="installation-date">
              <validation-provider #default="{ errors }" name="installation-date" rules="required">
                <flat-pickr v-model="deviceData.installationDate" class="form-control"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: inst Technician Name -->
          <b-col cols="12" md="3">
            <b-form-group :label="$t('Installer Technician')" label-for="tec">
              <validation-provider #default="{ errors }" name="tec" rules="required">
                <b-form-input id="tec" v-model="deviceData.instTechnicianName"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Type -->
          <b-col cols="12" md="2">
            <b-form-group :label="$t('Installed with lock')+'*'" label-for="type-customer">
              <validation-provider #default="{ errors }" name="type-customer" rules="required">
                <v-select :state="errors.length > 0 ? false:null" v-model="deviceData.hasBlock"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="lockingOptions"
                          :reduce="val => val.value" :clearable="false" input-id="type"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Notes -->
          <b-col cols="12" md="12">
            <b-form-group :label="$t('Note')" label-for="note">
              <b-form-textarea v-model="deviceData.note" id="note" rows="5"/>
            </b-form-group>
          </b-col>

          <!-- Btn: Save Submit -->
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm">
              {{ $t('Save') }}
            </b-button>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from 'bootstrap-vue'

import {
  VueAutosuggest
} from 'vue-autosuggest'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'
import router from '@/router'

import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'

import flatPickr from 'vue-flatpickr-component'

import store from '@/store'
import {
  ref,
  onUnmounted
} from '@vue/composition-api'

import deviceStoreModule from '../deviceStoreModule'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,

    VueAutosuggest,
    Cleave,
    vSelect,
    flatPickr

  },
  data() {
    return {
      //myId: router.currentRoute.params.id,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      //Datas autocomplete asset

      datasuggestAsset: [],
      filteredOptionsAsset: [],
      inputPropsAsset: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Select asset ...",
      },
      limit: 3,
      selectedAsset: null,

      //Datas autocomplete Device

      datasuggestDevice: [],
      filteredOptionsDevice: [],
      inputPropsDevice: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Select Device ...",
      },
      limit: 3,
      selectedDevice: null,

      deviceData: {
        deviceId: router.currentRoute.params.id,
        assetId: "",
        installationDate: "",
        instTechnicianName: "",
        hasBlock: false,
        note: ""
      },
      lockingOptions:[{value: false, label: "Não"}, {value: true, label: "Sim"}],
      

      dtSelected: ''

    }
  },
  created() {
    const DEVICE_APP_STORE_MODULE_NAME = 'app-device'

    // Register module
    if (!store.hasModule(DEVICE_APP_STORE_MODULE_NAME)) store.registerModule(DEVICE_APP_STORE_MODULE_NAME, deviceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEVICE_APP_STORE_MODULE_NAME)) store.unregisterModule(DEVICE_APP_STORE_MODULE_NAME)
    })
  },
  methods: {

    showModalInfo() {
      this.$swal({
        //position: 'top-end',
        icon: 'info',
        title: 'O ativo já possui um dispositivo instalado!',
        text: 'Deseja realmente instalar outro?',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        showConfirmButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-2',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('Sim');
        } else {
          console.log('Não');
          this.searchQuery = ''
          this.deviceData.assetId = ''

        }
      });
    },

    getSuggestionValue(option) {
      return option.item.plate || option.item.chassisNumber
    },

    msgDialog(msg, icon) {
      this.$swal({
        position: 'top-end',
        icon: icon,
        title: msg,
        showConfirmButton: false,
        timer: 1500 * 2,
        customClass: {
          confirmButton: 'btn btn-primary',
        },

        buttonsStyling: false,
      })
    },

    validationForm() {
      //alert('' + this.deviceData.installationDate)

      this.$refs.simpleRules.validate().then(success => {
        if (success) {

          this.deviceData.deviceId = router.currentRoute.params.id;
          //alert('form submitted! ' + this.deviceData.installationDate)
          console.log(this.deviceData)

          store.dispatch('app-device/installDevice', {

            deviceData: this.deviceData

          })
              .then(response => {
                //alert('Error 404')
                this.msgDialog('Success install device', 'success')
                router.push({
                  name: 'list-device'
                })

              })
              .catch(error => {
                this.msgDialog('FAILURE to install device ', 'error')
                // TODO: FAZER MSG ESPESSIFICA PARA CADAS ERRO DA API

                console.log(error)
              })

        }
      })
    },

    selectHandlerAsset(option) {
      this.selectedAsset = option.item
      this.filteredOptionsAsset = []
      this.searchQuery = option.item.plate || option.item.chassisNumber
      this.deviceData.assetId = option.item.id

      if (option.item.hasDevicesInstalled) this.showModalInfo()
    },

    onInputChangeAsset(text) {
      if (text === '' || text === undefined || text.length < 3) {
        this.filteredOptionsAsset = []
        return
      }

      store
          .dispatch('app-device/fetchAssets', {
            q: this.searchQuery,

          })
          .then(response => {
            console.log('resp auto', response.data.data)
            this.filteredOptionsAsset = [{
              data: response.data.data,
            }]
          })
          .catch((e) => {
            console.log(e);

          })
    },
    suggestionSelectedAsset() {

    },

    //Methods for autocomplete Device
    selectHandlerDevice(option) {
      this.selectedDevice = option.item
      this.filteredOptionsDevice = []
      this.searchQueryDevice = option.item.name
      this.deviceData.deviceId = option.item.id


    },

    onInputChangeDevice(text) {
      if (text === '' || text === undefined || text.length < 3) {

        return
      }

      store.dispatch('app-device/fetchDevices', {
        q: this.searchQuery,

      })
          .then(response => {


            this.filteredOptionsDevice = [{
              data: response.data.data,
            }]

          })
          .catch(error => {

            console.log(error)

          })
    },
    suggestionSelectedDevice() {

    }

  },
  setup() {
    //searchQueryAssets
    const searchQuery = ref(``)

    return {
      searchQuery
    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>

</style>
