import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDevices(ctx, queryParams) {

      return new Promise((resolve, reject) => {
        axios
          //.get('/apps/device/devices', { params: queryParams })
          .get(`/api/v1/device/search?Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllDevices(ctx, queryParams) {
      const baseUrl = '/api/v1/device/searchall';
      const urlParams = new URLSearchParams();

      urlParams.append('Search', queryParams.q);
      urlParams.append('Page', queryParams.page);
      urlParams.append('PerPage', queryParams.perPage);

      if (queryParams.ownerId) {
        urlParams.append('OwnerId', queryParams.page);
      }
      

      const url = `${baseUrl}?${urlParams.toString()}`;

      return new Promise((resolve, reject) => {
        axios
            //.get('/apps/device/devices', { params: queryParams })
            .get(url)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchDevice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          //.get(`/apps/device/devices/${id}`)
          .get(`/api/v1/device?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDevice(ctx, deviceData) {
      return new Promise((resolve, reject) => {
        let device = deviceData.deviceData
        axios
          //.post('/apps/device/devices', { device: deviceData })
          .post('/api/v1/Device', device)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBulk(ctx, deviceData) {
      console.log(`DEVICE DATA `, deviceData)
      return new Promise((resolve, reject) => {
        let device = deviceData.deviceData
        axios
          //.post('/apps/device/devices', { device: deviceData })
          .post('/api/v1/Device/createdevicebulk', device)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDevice(ctx, deviceData) {
      return new Promise((resolve, reject) => {
        let device = deviceData.deviceData
        axios
          //.post('/apps/device/devices', { device: deviceData })
          .put('/api/v1/Device', device)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMobileOperators(ctx,) {

      return new Promise((resolve, reject) => {

        axios
          //.get('/apps/asset/assets', { params: queryParams })
          .get(`/api/v1/Device/searchmobileoperator?Page=1&PerPage=10`)
          ///api/v1/Asset/searchmanufacturer?Page=1&PerPage=10
          //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMobileProviders(ctx,) {

      return new Promise((resolve, reject) => {

        axios
          //.get('/apps/asset/assets', { params: queryParams })
          .get(`/api/v1/Device/searchmobileoperatorprovider?Page=1&PerPage=10`)
          ///api/v1/Asset/searchmanufacturer?Page=1&PerPage=10
          //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDeviceModel(ctx,) {

      return new Promise((resolve, reject) => {

        axios
          //.get('/apps/asset/assets', { params: queryParams })
          .get(`/api/v1/Device/searchdevicemodel?Page=1&PerPage=20`)
          ///api/v1/Asset/searchmanufacturer?Page=1&PerPage=10
          //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSimCard(ctx, queryParams) {

      return new Promise((resolve, reject) => {

        axios
          //.get('/apps/asset/assets', { params: queryParams })
          .get(`/api/v1/Device/searchsimcard?Search=${queryParams.q}&Page=1&PerPage=5`)
          //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAssets(ctx, queryParams) {

      return new Promise((resolve, reject) => {

        axios
          .get(`/api/v1/Asset/searchtoinstall?Search=${queryParams.q}&Page=1&PerPage=10`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    installDevice(ctx, deviceData) {
      return new Promise((resolve, reject) => {
        console.log('Device install',deviceData)
        let device = deviceData.deviceData
        axios
          //.post('/apps/device/devices', { device: deviceData })
          .post('/api/v1/Device/InstallDevice', device)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uninstallDevice(ctx, deviceData) {
      return new Promise((resolve, reject) => {
        console.log('Device install',deviceData)
        let device = deviceData.deviceData
        axios
          //.post('/apps/device/devices', { device: deviceData })
          .post('/api/v1/Device/DeinstallDevice', device)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProvider(ctx, { number }) {
      return new Promise((resolve, reject) => {
        console.log('number  ', number)
        axios
          //.get(`/apps/device/devices/${id}`)
          .get(`/api/v1/Device/GetSimCard?number=${number}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteDevice(ctx, { id }) {
      return new Promise((resolve, reject) => {
      
          axios
              //.post('/apps/customer/customers', { customer: customerData })
              .delete(`/api/v1/Device?Id=${id}`)
              .then(response => resolve(response))
              .catch(error => reject(error))
      })
  },

  },

  //
}
